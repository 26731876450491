import { useConfigStore } from '@/stores/config'

export function usePrice() {
  const { $i18n } = useNuxtApp()
  const showVAT = computed(() => {
    const configStore = useConfigStore()
    return configStore.getShowVAT
  })

  const localePrice = (value, showVat = false, VAT = false) => {
    const price = Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'EUR',
    })
    if (showVat) {
      if (VAT) return `${price} ${$i18n.t('actions.VAT')}`
      return `${price} ${$i18n.t('actions.noVAT')}`
    }
    return price
  }
  return {
    showVAT,
    localePrice,
  }
}
